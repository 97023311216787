@import '../../../../scss/index.scss';
.btn-quote {
  background-color: $background-blue-dark;
  border-radius: 4px;
  width: 35px;
  height: 35px;
  transition: 0.3s;
  font-size: 16px;
  color: white;
  margin-left: 10px;

  &:hover {
    background-color: lighten($color: $background-blue-dark, $amount: 10);
    color: white;
    border-color: $background-blue-dark;
  }

  &:focus {
    background-color: lighten(
      $color: $background-blue-dark,
      $amount: 10
    ) !important;
    color: white !important;
    border-color: $background-blue-dark !important;
  }

  &:active {
    background-color: lighten($color: $background-blue-dark, $amount: 10);
    color: white;
    border-color: $background-blue-dark;
  }
}

.btn-enviar {
  background-color: #68d78a;
  border-radius: 4px;
  width: 200px;
  height: 40px;
  transition: 0.3s;
  font-size: 16px;
  color: white;
  margin-left: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: #6ce691;
    color: white;
    border-color: #68d78a;
  }
}
.btn-pdf-mobile {
  background-color: #1890ff;
  color: white;
  border-radius: 4px;
  width: 200px;
  height: 40px;
  transition: 0.3s;
  font-size: 16px;
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  margin-left: 20px;
  text-align: center;
  padding-top: 5px;
}
